import { useEffect, useRef, useState, useContext } from "react";
import Button from "./ui/button";
import InputWithoutLabel from "./ui/input-without-label";
import { formatFileSize } from "src/lib/number";
import { MAX_FILES } from "src/lib/constants";
import GlobalContext from "src/context/global-context";
import { CrossIcon } from "./icons/cross-icon";

const FileSelection = ({
  label,
  curFiles,
  curDescr,
  onFileChange,
  onDescrChange,
}: {
  label: string;
  curFiles: File[];
  curDescr: string;
  onFileChange: any;
  onDescrChange: any;
}) => {
  const { setAlertText, setAlertTitle, setIsAlertOpened } =
    useContext(GlobalContext);

  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [strDescr, setDescr] = useState("");

  const [fileJSXs, setFileJSXs] = useState<JSX.Element[]>([]);

  const onClick = () => {
    if (fileRef.current) {
      fileRef.current?.click();
    }
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    const fileList = Array.from(selectedFiles!);
    const totalFiles = [...files, ...fileList];

    if (totalFiles.length > MAX_FILES) {
      setAlertTitle("Error");
      setAlertText(`You can add ${MAX_FILES} files as max!`);
      setIsAlertOpened(true);

      return;
    }

    setFiles(totalFiles);
    onFileChange(totalFiles);
  };

  useEffect(() => {
    setFiles(curFiles);
    setDescr(curDescr);
  }, [curFiles, curDescr]);

  const onRemoveFile = (nFileIdx: number) => {
    const updatedFiles = files.filter((item, index) => index != nFileIdx);
    setFiles([...updatedFiles]);
  };

  useEffect(() => {
    const elements = files.map((file, index) => (
      <li
        key={file.name}
        className="flex flex-row items-center justify-between gap-0"
      >
        <label className="font-gilroy-semibold text-primary text-[16px]">
          {file.name} - {formatFileSize(file.size)}
        </label>
        <button
          className="w-4 h-4 flex items-center justify-center flex-shrink-0"
          onClick={() => onRemoveFile(index)}
        >
          <CrossIcon className="text-primary w-3 h-3" />
        </button>
      </li>
    ));

    setFileJSXs(elements);
  }, [files]);

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col gap-1">
        <div className="flex flex-row items-start gap-2 flex-shrink-0">
          <label className="block cursor-pointer text-[16px] font-bold text-secondary dark:text-light/70">
            {label}
          </label>
          <div className="flex flex-row items-start gap-2">
            <Button
              variant="text"
              className="bg-active hover:bg-blue-dark-400 text-white rounded-[8px] px-3 py-1 text-[12px]"
              onClick={onClick}
            >
              {"Choose File"}
            </Button>
            <input
              ref={fileRef}
              multiple
              className="hidden"
              type="file"
              onChange={onHandleChange}
            />
          </div>
        </div>
        {files && <ul>{fileJSXs}</ul>}
      </div>
      <InputWithoutLabel
        placeholder="Enter description"
        className="w-full mt-2"
        value={strDescr}
        onChange={(e) => {
          setDescr(e.target.value);
          onDescrChange(e.target.value);
        }}
      />
    </div>
  );
};

export default FileSelection;
