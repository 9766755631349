import { forwardRef } from "react";
import classNames from "classnames";
import { CrossIcon } from "../icons/cross-icon";

type InputWithoutLabelProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: boolean;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  allCaps?: boolean;
  isSmall?: boolean;
  clickedClose?: any;
};

const InputWithoutLabel = forwardRef<HTMLInputElement, InputWithoutLabelProps>(
  (
    {
      label,
      error = false,
      type = "text",
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      allCaps = false,
      isSmall = false,
      clickedClose,
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        <label className="block text-16px font-medium text-secondary relative">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-medium text-secondary dark:text-light/70">
              {required && <span className="text-[14px] text-error">* </span>}
              {label}
            </span>
          )}
          <input
            type={type}
            ref={ref}
            {...props}
            className={classNames(
              `${
                isSmall ? "h-[32px]" : "h-[44px]"
              } placeholder-gray-modern-400 ${
                error
                  ? "border-[1px] border-error focus:ring-error focus:border-error"
                  : "border-none"
              } w-full appearance-none text-[14px] font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5`,
              allCaps && "uppercase",
              inputClassName
            )}
          />
        </label>
      </div>
    );
  }
);

InputWithoutLabel.displayName = "InputWithoutLabel";
export default InputWithoutLabel;
