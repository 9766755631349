import { forwardRef } from "react";
import cn from "classnames";
import { SpinnerIcon } from "../icons/spinner-icon";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: "text" | "outline" | "solid" | "icon" | "solidDanger" | "validate";
}

const variantClasses = {
  text: "text-primary dark:text-light",
  outline:
    "h-[38px] rounded py-3 px-3 md:px-5 bg-transparent border border-primary dark:border-light-400 text-primary dark:text-light hover:bg-light-400 dark:hover:bg-dark-600 ",
  solid: "h-[38px] rounded py-3 px-3 md:px-5 text-primary",
  validate: "h-[38px] rounded py-3 px-3 md:px-5 text-primary",
  icon: "transition-colors duration-200 text-dark-800 hover:text-dark-900 dark:hover:text-light-600",
  solidDanger: "h-[33px] rounded text-white bg-error hover:bg-red-600",
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, isLoading, disabled, children, variant = "solid", ...props },
    ref
  ) => (
    <button
      ref={ref}
      className={cn(
        "transition-fill-colors flex items-center justify-center gap-2 duration-200 appearance-none",
        isLoading || disabled
          ? "pointer-events-none cursor-not-allowed"
          : "pointer-events-auto cursor-pointer",
        disabled ? "opacity-70" : "opacity-100",
        variant === "validate"
          ? disabled
            ? "opacity-70 bg-[#BCBEAD]"
            : "opacity-100 bg-active text-white hover:bg-main"
          : "",
        variantClasses[variant],
        className
      )}
      {...props}
    >
      {isLoading && <SpinnerIcon className="h-auto w-5 animate-spin" />}
      {children}
    </button>
  )
);

Button.displayName = "Button";
export default Button;
