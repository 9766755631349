import { FC, Suspense, useEffect, useState } from "react";
import { SpinnerIcon } from "src/components/icons/spinner-icon";
import GlobalContextWrapper from "src/context/global-context-wrapper";
import MainPage from "./main";

const App: FC = () => {
  return (
    <Suspense
      fallback={
        <div className="bg-whole-gray flex items-center justify-center w-full min-h-screen backdrop-blur-sm">
          <SpinnerIcon className="h-auto w-8 animate-spin text-active" />
        </div>
      }
    >
      <GlobalContextWrapper>
        <MainPage />
      </GlobalContextWrapper>
    </Suspense>
  );
};

export default App;
