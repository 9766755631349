import classNames from "classnames";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Empty, Select } from "antd";

interface DropdownProps {
  id?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  data: any[];
  value?: string;
  onChange?: any;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
}
const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      id,
      label,
      placeholder = "",
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      value,
      onChange,
      data,
      required = false,
      showInfo = false,
      info,
      ...props
    },
    ref
  ) => {
    const [selectedId, setSelectedId] = useState(value);

    useEffect(() => {
      setSelectedId(value);
    }, [value]);

    const onSearch = (value: string) => {
      console.log("search:", value);
    };

    return (
      <div id={id ?? "none"} className={className}>
        <label className="block text-16px  font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-medium text-secondary dark:text-light/70">
              {required && <span className="text-[14px] text-error">* </span>}
              {label}
            </span>
          )}
          <Select
            optionFilterProp={"label"}
            value={selectedId}
            style={{ flex: 1, width: "100%", height: 44 }}
            onChange={(value) => {
              onChange && onChange(value);
              setSelectedId(value);
            }}
            placeholder={placeholder}
            options={data.map((item) => ({
              value: item.id,
              label: item.fullName,
            }))}
          />
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span role="alert" className="block pt-2 text-[12px] text-error ">
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

Dropdown.displayName = "Dropdown";
export default Dropdown;
