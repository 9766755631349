import { createContext } from "react";

interface IGlobalContext {
  isAlertOpened: boolean;
  alertTitle: string;
  alertText: string;
  setIsAlertOpened: (opened: boolean) => void;
  setAlertText: (text: string) => void;
  setAlertTitle: (text: string) => void;
}

const defaultState = {
  isAlertOpened: false,
  alertText: "",
  alertTitle: "",
  setIsAlertOpened: (opened: boolean) => {},
  setAlertText: (text: string) => {},
  setAlertTitle: (text: string) => {},
};

const GlobalContext = createContext<IGlobalContext>(defaultState);
export default GlobalContext;
