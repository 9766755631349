// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQNdURPdmxspuMFWXDOXMAiGbS58SbxxE",
  authDomain: "loci-landscapes.firebaseapp.com",
  projectId: "loci-landscapes",
  storageBucket: "loci-landscapes.appspot.com",
  messagingSenderId: "680102620802",
  appId: "1:680102620802:web:fe6ae29c3835c74cdfce9b",
  measurementId: "G-ET35B0CTWQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const storage = getStorage(app);

export default async function uploadImage(file: File) {
  if (!file) {
    console.error("No file provided");
    return null;
  }

  try {
    // Create a storage reference
    const storageRef = ref(storage, `images/${file.name}`);

    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL; // Returns the URL for the uploaded image
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
}
