import { useState, useEffect, FC, ReactNode } from "react";
import GlobalContext from "./global-context";

export default function GlobalContextWrapper({
  children,
}: React.PropsWithChildren<{}>) {
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  return (
    <GlobalContext.Provider
      value={{
        isAlertOpened,
        setIsAlertOpened,
        alertText,
        setAlertText,
        alertTitle,
        setAlertTitle,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
